"use client";

import { Box, SxProps } from "@mui/material";
import React, { PureComponent } from "react";

interface FontAwesomeIconInterface {
    icon?: string;
    size?: number;
    color?: string;
    sx?: SxProps
}
export default class FontAwesomeIcon extends PureComponent<FontAwesomeIconInterface> {
    render() {
        return (
            <Box
                component='i'
                className={this.props.icon || "fa-duotone fa-question"}
                sx={{
                    fontSize: this.props.size || 20,
                    color: this.props.color || "#323232",
                    ...this.props.sx
                }}
            />
        );
    }
}
