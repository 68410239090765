import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartType, ClientCartItemType } from "../../../types/cart";
import { ProductDetailType } from "@/app/types/product-site";
import { PaymentMethod } from "@/app/types/payment";
import { DeliveryType } from "@/app/types/delivery";

export type PaymentType = "portal" | "c2c" | "cash";

type CartState = {
	serverCart: CartType | null;
	clientCart: {
		items: ClientCartItemType[];
		paymentMethod: PaymentMethod | null;
		paymentType: PaymentType;
	};
	loading: boolean;
	submitLoading: boolean;
	fetchingCart: boolean;
	redirectingToGateway: boolean;
};

const initialState: CartState = {
	serverCart: null,
	clientCart: {
		items: [],
		paymentMethod: null,
		paymentType: "portal",
	},
	loading: false,
	fetchingCart: false,
	submitLoading: false,
	redirectingToGateway: false,
};

const cartSlice = createSlice({
	name: "cart",
	initialState,

	reducers: {
		startFetching(state) {
			state.fetchingCart = true;
		},
		stopFetching(state) {
			state.fetchingCart = false;
		},
		startLoading(state) {
			state.loading = true;
		},
		stopLoading(state) {
			state.loading = false;
		},
		startSubmitLoading(state) {
			state.submitLoading = true;
		},
		stopSubmitLoading(state) {
			state.submitLoading = false;
		},
		startRedirectingToGateway(state) {
			state.redirectingToGateway = true;
		},
		stopRedirectingToGateway(state) {
			state.redirectingToGateway = false;
		},
		updateCart(state, action: PayloadAction<CartType>) {
			state.serverCart = action.payload;
		},
		clearClientCart(state) {
			state.clientCart = {
				items: [],
				paymentMethod: null,
				paymentType: "portal",
			};
            localStorage.removeItem('client_cart')
		},
		setPaymentMethod(state, action: PayloadAction<PaymentMethod>) {
			state.clientCart.paymentMethod = action.payload;
		},
		setPaymentType(state, action: PayloadAction<PaymentType>) {
			state.clientCart.paymentType = action.payload;
		},
		clearServerCart(state) {
			state.serverCart = null;
		},
		clearCart(state) {
			//Remove client cart
			state.clientCart = {
				items: [],
				paymentMethod: null,
				paymentType: "portal",
			};
			//Remove server cart
			state.serverCart = null;
			state.fetchingCart = false;
            localStorage.removeItem('client_cart')
		},
		addToClientCart(state, action: PayloadAction<ProductDetailType>) {
			const prevClientCartItemIndex = state.clientCart?.items?.findIndex((item) => item?.product?.product_id === action?.payload?.product_id);

			if (prevClientCartItemIndex >= 0) {
				const prevItem = state.clientCart.items[prevClientCartItemIndex];

				//Add qty to prev one
				state.clientCart.items[prevClientCartItemIndex] = {
					product: action.payload,
					qty: prevItem?.qty + 1,
				};
			} else {
				state.clientCart.items = [
					...state.clientCart.items,
					{
						product: action.payload,
						qty: 1,
					},
				];
			}
			localStorage.setItem("client_cart", JSON.stringify(state.clientCart));
		},
		removeFromClientCart(state, action: PayloadAction<ProductDetailType>) {
			const prevClientCartItemIndex = state.clientCart?.items?.findIndex((item) => item?.product?.product_id === action?.payload?.product_id);

			if (prevClientCartItemIndex >= 0) {
				const prevItem = state.clientCart.items[prevClientCartItemIndex];

				const newQty = prevItem?.qty - 1;

				if (newQty === 0) {
					//Should remove product
					state.clientCart.items = state.clientCart.items.filter((_, i) => i !== prevClientCartItemIndex);
				} else {
					state.clientCart.items[prevClientCartItemIndex] = {
						product: action.payload,
						qty: prevItem?.qty - 1,
					};
				}
			} else {
				state.clientCart.items.filter((item) => item?.product?.product_id !== action?.payload?.product_id);
			}

			localStorage.setItem("client_cart", JSON.stringify(state.clientCart));
		},
		updateClientCart(state, action: PayloadAction<any>) {
			state.clientCart = action.payload;
		},
		updateCartDeliveryMethod(state, action: PayloadAction<DeliveryType>) {
			const minPrice = action.payload.min_price_to_skip;
			const isFreeDelivery = state.serverCart?.total_price >= minPrice;
			let finalAmount = action.payload.price;
			if (isFreeDelivery) finalAmount = 0;
			let cartPricing = state?.serverCart?.pricing;
			let findedDelivery = cartPricing.find((p) => p.key === "delivery");
			const deliveryIndex = cartPricing.findIndex((item) => item.key === "delivery");
			if (findedDelivery) {
				findedDelivery = {
					...findedDelivery,
					amount: finalAmount,
				};
				cartPricing[deliveryIndex] = findedDelivery;
			}
			state.serverCart.pricing = cartPricing;
		},
	},
});

export const {
	updateCart,
	clearCart,
	clearClientCart,
	clearServerCart,
	addToClientCart,
	removeFromClientCart,
	setPaymentMethod,
	setPaymentType,
	startLoading,
	updateCartDeliveryMethod,
	startSubmitLoading,
	stopSubmitLoading,
	stopLoading,
	startFetching,
	stopFetching,
	startRedirectingToGateway,
	stopRedirectingToGateway,
	updateClientCart,
} = cartSlice.actions;

export default cartSlice;
