"use client";
import { createTheme } from "@mui/material";
import colors from "./color";

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs2: true;
        lg2: true;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            xs2: 320,
            sm: 600,
            md: 900,
            lg: 1200,
            lg2: 1416,
            xl: 1536,
        }
    },
    typography: {
        fontFamily: ["var(--main-font)", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#BB202E",
        },
        secondary: {
            main: '#242424',
        }
    },
    components: {
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         root: {
        //             "&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline": {
        //                 borderColor: "red",
        //             },
        //         },
        //     },
        // },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: colors.primary,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: colors.primary,
                    },
                    "&:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#fff",
                    },
                    "&.MuiInputBase-colorPrimary": {
                        background: "#0000000A",
                        "&.Mui-focused": {
                            background: "#fff",
                        },
                    },
                },
                // input: {
                //     border: "none",
                //     backgroundColor: "#0000000A",
                //     borderRadius: 8,
                //     ":focus": {
                //         background: "none",
                //     },
                // },
                notchedOutline: {
                    borderColor: "#fff",
                },
            },
        },
    },
    shape: {
        borderRadius: 8,
    },
});

export default theme;
