"use client"

import { IconButton, ThemeProvider } from "@mui/material";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v15-appRouter";
import FontAwesomeIcon from "@v2/components/FontAwesomeIcon";
import theme from "@v2/lib/theme";
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { ReactNode, useMemo } from "react";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";

const MuiProvider = ({
    children,
}: {
    children: ReactNode
}) => {
    const getRouterOptions = useMemo(() => ({ stylisPlugins: [prefixer, stylisRTLPlugin] }), [])
    return (
        <AppRouterCacheProvider options={getRouterOptions} >
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={1} autoHideDuration={2000} action={(snackbarId) => (
                    <IconButton onClick={() => closeSnackbar(snackbarId)} color="inherit">
                        <FontAwesomeIcon icon="fa-light fa-xmark" color="inherit" />
                    </IconButton>
                )}>
                    {children}
                </SnackbarProvider>
            </ThemeProvider>
        </AppRouterCacheProvider>
    )
}

export default MuiProvider;