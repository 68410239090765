import { AddressType } from "@/app/types/address";
import { AttachmentType } from "@/app/types/attachment";
import { ClientCartItemType } from "@/app/types/cart";
import { DeliveryTime, DeliveryType } from "@/app/types/delivery";
import { ProductDetailType, ProductSiteType } from "@/app/types/product-site";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type ServerTryCartStatus = string;

export type ServerCartItemType = {
	id: number;
	product_id: number;
	product_name: string;
	price: number;
	images: AttachmentType[];
};

export type ServerTryCartType = {
	id: number;
	status: ServerTryCartStatus;
	unique: any;
	delivered_date: any;
	delivery_code: number | string;
	created_at: string;
	updated_at: string;
	shipper: any;
	address: AddressType | null;
	items: ServerCartItemType[];
};

export type TryCartState = {
	serverTryCart: ServerTryCartType;
	clientTryCart: {
		items: ClientCartItemType[];
	};
	loading: boolean;
	deliveryMethod: DeliveryType | null;
	deliveryTime: DeliveryTime | null;
	fetchingCart: boolean;
	deliveryDate: string | null;
	address: AddressType | null;
};

const initialState: TryCartState = {
	serverTryCart: null,
	clientTryCart: {
		items: [],
	},
	fetchingCart: false,
	loading: false,
	deliveryMethod: null,
	deliveryTime: null,
	deliveryDate: null,
	address: null,
};

const tryCartSlice = createSlice({
	name: "tryCart",
	initialState,
	reducers: {
		startLoading(state) {
			state.loading = true;
		},
		stopLoading(state) {
			state.loading = false;
		},
		addToClientCart(state, action: PayloadAction<ProductDetailType>) {
			const inStorage = localStorage.getItem("client_try_cart");

			if (inStorage) {
				state.clientTryCart.items = JSON.parse(inStorage);
			}
			state.clientTryCart.items.push({
				product: action.payload,
				qty: 1,
			});

			localStorage.setItem("client_try_cart", JSON.stringify(state.clientTryCart.items));
		},
		removeFromClientCart(state, action: PayloadAction<ProductDetailType>) {
			state.clientTryCart.items = state.clientTryCart.items.filter((item) => item?.product?.product_id !== action?.payload?.product_id);
			localStorage.setItem("client_try_cart", JSON.stringify(state.clientTryCart.items));
		},
		updateTryCart(state, action: PayloadAction<any>) {
			state.serverTryCart = action.payload;
		},
		updateClientTryCart(state, action: PayloadAction<any>) {
			state.clientTryCart.items = action.payload;
		},
		updateTryCartDeliveryMethod(state, action: PayloadAction<DeliveryType>) {
			state.deliveryMethod = action.payload;
		},
		updateTryCartDeliveryTime(state, action: PayloadAction<DeliveryTime>) {
			state.deliveryTime = action.payload;
		},
		updateTryCartDeliveryDate(state, action: PayloadAction<string>) {
			state.deliveryDate = action.payload;
			if (!!state.deliveryTime) state.deliveryTime = null;
		},
		updateTryCartAddress(state, action: PayloadAction<AddressType>) {
			state.address = action.payload;
		},
		clearClientTryCart(state) {
			state.clientTryCart = {
				items: [],
			};
			localStorage.setItem("client_try_cart", JSON.stringify(state.clientTryCart.items));
		},
		clearServerCart(state) {
			state.serverTryCart = null;
		},
		clearTryCart: () => initialState,
		startTryCartFetching(state) {
			state.fetchingCart = true;
		},
		stopTryCartFetching(state) {
			state.fetchingCart = false;
		},
	},
});

export const {
	updateTryCart,
	updateClientTryCart,
	clearTryCart,
	clearClientTryCart,
	clearServerCart,
	startTryCartFetching,
	stopTryCartFetching,
	startLoading,
	stopLoading,
	addToClientCart,
	removeFromClientCart,
	updateTryCartDeliveryMethod,
	updateTryCartDeliveryTime,
	updateTryCartDeliveryDate,
	updateTryCartAddress,
} = tryCartSlice.actions;

export default tryCartSlice;
