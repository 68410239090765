const colors = {
  primary: "#BB202E",
  secondary: "#4543C1",
  lightsecondary: "#E8EAF6",
  success: "#1D7B24",
  lightSuccess: "#E8F5E9",
  error: "#D60808",
  lightError: "#FFE8EB",
  warning: "#BF360C",
  lightWarning: "#FFF3E0",
};

export const uiColors = colors;

export default colors;
