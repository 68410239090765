import { UserSession } from "@/types/user-session";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
	session: UserSession | null;
	headerIsShow: boolean;
} = {
	session: null,
	headerIsShow: true,
};

const generalSlice = createSlice({
	name: "general",
	initialState,
	reducers: {
		storeSessionToCsr(state, action: PayloadAction<null | UserSession>) {
			state.session = action.payload;
		},
		setHeaderIsShow(state, action: PayloadAction<boolean>) {
			state.headerIsShow = action.payload;
		},
	},
});

export const { storeSessionToCsr, setHeaderIsShow } = generalSlice.actions;
export default generalSlice;
