import(/* webpackMode: "eager" */ "/home/website/Projects/vahdatoptic-website/node_modules/next/font/local/target.css?{\"path\":\"src/app/v2/lib/font.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--main-font\",\"src\":[{\"path\":\"../assets/fonts/IRANYekanX/woff2/IRANYekanX-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/IRANYekanX/woff2/IRANYekanX-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/IRANYekanX/woff2/IRANYekanX-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/IRANYekanX/woff2/IRANYekanX-DemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/IRANYekanX/woff2/IRANYekanX-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/home/website/Projects/vahdatoptic-website/node_modules/next/font/local/target.css?{\"path\":\"src/app/v2/lib/fontawesomeLoader.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--fa-icon\",\"src\":[{\"path\":\"../assets/icons/fontawesome-pro-6.7.2/webfonts/fa-light-300.ttf\",\"style\":\"normal\",\"weight\":\"300\"},{\"path\":\"../assets/icons/fontawesome-pro-6.7.2/webfonts/fa-duotone-900.ttf\",\"style\":\"normal\",\"weight\":\"900\"},{\"path\":\"../assets/icons/fontawesome-pro-6.7.2/webfonts/fa-brands-400.ttf\",\"style\":\"normal\",\"weight\":\"400\"}]}],\"variableName\":\"faIcon\"}");
;
import(/* webpackMode: "eager" */ "/home/website/Projects/vahdatoptic-website/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/v2/components/SessionManagement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/v2/lib/muiProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/v2/lib/ProgressBarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/website/Projects/vahdatoptic-website/src/app/v2/lib/storeProvider.tsx");
