"use client";

import generalSlice from "@/app/store/slices/general";
import { configureStore } from "@reduxjs/toolkit";

import cartSlice from "@/app/store/slices/cart/cartSlice";
import tryCartSlice from "@/app/store/slices/try-cart/tryCartSlice";
import { thunk } from "redux-thunk";

export const makeStore = () => {
	return configureStore({
		reducer: {
			cart: cartSlice.reducer,
			tryCart: tryCartSlice.reducer,
			general: generalSlice.reducer,
		},
		middleware: (getDefaultMiddleware) => {
			return getDefaultMiddleware().concat(thunk);
		},
	});
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
