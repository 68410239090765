"use client"

import { storeSessionToCsr } from '@/app/store/slices/general';
import { UserSession } from '@/types/user-session';
import { useAppDispatch } from '@v2/lib/redux/hooks';
import { Fragment, useEffect } from 'react';

const SessionManagement = ({ session }: { session: UserSession }) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (session.isAuthenticated) {
            localStorage.setItem('accessToken', session.data.accessToken)
        }
        dispatch(storeSessionToCsr(session))
    }, [session.isAuthenticated])

    return (
        <Fragment />
    );
}

export default SessionManagement;
