'use client';

import { AppProgressProvider } from '@bprogress/next';
import { colors, NoSsr } from '@mui/material';

import { ReactNode } from 'react';

const ProgressBarProvider = ({ children }: { children: ReactNode }) => {
    return (
        <AppProgressProvider height="3px"
            color={colors.grey[800]}
            options={{ showSpinner: false, speed: 200 }}
            shallowRouting>
            {children}
        </AppProgressProvider>
    );
};

export default ProgressBarProvider;